
import { message } from "ant-design-vue";
import { defineComponent, reactive, toRefs } from "vue";
import * as API from "@/API/sysParam";
export default defineComponent({
  setup() {
    const state = reactive({
      deferDays: "0",
      deferExtendDays: "0",
      mdfStopDays: "0",
      vanLicenseDeferDays: "0"
    });
    API.getBasicData().then(res => {
      Object.assign(state, res);
    });
    const validate = (arg: string) => {
      if (
        !isNaN(Number(state[arg])) &&
        Number(state[arg]) >= 0 &&
        Number(state[arg]) < 10000000000
      ) {
        return true;
      } else {
        message.error("请输入0到999999999之间的数字");
        return false;
      }
    };
    const updateParameter = (arg: string) => {
      switch (arg) {
        case "deferDays":
          validate("deferDays") &&
            API.updateDeferDays(String(state.deferDays)).then(res => {
              res === "" && message.success("操作成功");
            });
          break;
        case "deferExtendDays":
          validate("deferExtendDays") &&
            API.updateDeferExtendDays(String(state.deferExtendDays)).then(
              res => {
                res === "" && message.success("操作成功");
              }
            );
          break;
        case "mdfStopDays":
          validate("mdfStopDays") &&
            API.updateMdfStopDays(String(state.mdfStopDays)).then(res => {
              res === "" && message.success("操作成功");
            });
          break;
        case "vanLicenseDeferDays":
          validate("vanLicenseDeferDays") &&
            API.updateVanLicenseDeferDays(
              String(state.vanLicenseDeferDays)
            ).then(res => {
              res === "" && message.success("操作成功");
            });
          break;
      }
    };
    return {
      ...toRefs(state),
      updateParameter
    };
  }
});
